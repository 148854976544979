<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="sexe_code">{{ $t('horse.sexe') }}</label>
		<div :class="colSize">
			<b-input-group :append="append">
				<e-select
					id="sexe_code"
					v-model="selected_sexe"
					track-by="sexe_code"
					label="sexe_label"
					:placeholder="$t('horse.sexe_cheval')"
					:options="horse_sexes"
					:searchable="true"
					:allow-empty="false"
					:loading="is_working"
					:show-labels="false"
					:disabled="!isEditable"
					@input="onChange"
				/>
			</b-input-group>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [Config, HorseMixin],
		props: [
			'horse_sexe_code',
			'editable',
			'col',
			'append_sex'
		],
		data (){
			return {
				horse_sexes: [],
				selected_sexe: null,
				is_working: true,
				append: this.getTrad('horse.sexe'),
				col_size: 8
			}
		},
		computed: {
			isEditable () {
				if(this.horse_sexe_code === 'M' || this.horse_sexe_code === 'IND') {
					return true
				}

				return this.editable
			},
			colSize(){
				return 'col-' + this.col_size
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.horse_sexes = await this.getSexes()

				if(this.isEditable && this.horse_sexe_code === 'M') {
					this.horse_sexes = this.horse_sexes.filter(sexe => {
						return sexe.sexe_code === "H" || sexe.sexe_code === "M"
					})
				}
				if (this.append_sex !== undefined && !this.append_sex){
					this.append = false
				}
				if (this.col !== undefined){
					this.col_size = this.col
				}
				this.checkSelect()
				this.is_working = false
			},
			checkSelect() {
				this.selected_sexe = this.horse_sexes.find(hs => hs.sexe_code == this.horse_sexe_code)
			},
			onChange(obj) {
				this.$emit('change', obj.sexe_code)
			}
		},
		watch: {
			horse_sexe_code() {
				this.checkSelect()
			}
		}
	}
</script>
